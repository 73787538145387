import React from 'react';
import calenderIcon from '../../../../assets/images/calenderIcon.svg?url';
import helmetIcon from '../../../../assets/images/HelmetIcon.svg?url';
import mapIcon from '../../../../assets/images/mapIcon.svg?url';
import winterIcon from '../../../../assets/images/winterIcon.svg?url';
import nafSenterIcon from '../../../../assets/images/nafSenterIcon.svg?url';
import wheelChangeIcon from '../../../../assets/images/wheelChangeIcon.svg?url';
import helmetDarkIcon from '../../../../assets/images/helmetDarkIcon.svg?url';

type Props = {
  image: {
    url?: string;
    caption?: string;
    source?: string;
    name?: string;
  };
  className?: string;
};

const EventImage = ({ image: { url, caption, source, name }, className }: Props) => {
  if (source !== 'NafIllustration' && url) {
    return <img src={url} alt={caption} className={className} />;
  }
  if (name === 'HelmetIcon') {
    return <img src={helmetIcon} alt="Hjelm" className={className} />;
  }
  if (name === 'CalendarIcon') {
    return <img src={calenderIcon} alt="Kalender" className={className} />;
  }
  if (name === 'MapIcon') {
    return <img src={mapIcon} alt="Kart" className={className} />;
  }
  if (name === 'ConeIcon') {
    return <img src={winterIcon} alt="Kjegle" className={className} />;
  }
  if (name === 'NafSenterIcon') {
    return <img src={nafSenterIcon} alt="NAF Senter" className={className} />;
  }
  if (name === 'WheelChangeIcon') {
    return <img src={wheelChangeIcon} alt="Hjulskift" className={className} />;
  }
  if (name === 'HelmetDarkIcon') {
    return <img src={helmetDarkIcon} alt="Hjelm med mørkt visir" className={className} />;
  }
  return null;
};

export default EventImage;
